import React, { useState, useEffect, useRef } from 'react';
import './otp.css'
import { useNavigate } from 'react-router-dom';

function OtpVerification() {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(null);
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    useEffect(() => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            // Use a different variable name here to avoid shadowing the state variable
            const storedUserDetails = JSON.parse(userDetailsString);
            console.log(storedUserDetails.otp, 'generated otp');
            setUserDetails(storedUserDetails); // Set the state with the stored user details
        }
    }, []);
    

    const handleOtpChange = (index, value) => {
        if (!/^\d*$/.test(value)) {
          setOtpError('Please enter only numeric values');
          return;
        }
    
        const newOtp = [...otp];
        newOtp[index] = value;
    
        // Move focus to the next input field if a digit is entered
        if (value && index < otp.length - 1) {
          otpInputRefs[index + 1].current.focus();
        }
    
        setOtp(newOtp);
        setOtpError('');
    };

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('').trim();
        console.log('Entered OTP:', enteredOtp);
    
        if (userDetails && enteredOtp === userDetails.otp) {
            
            console.log('OTP Matched. Proceed with further actions.');
    
            // Make an API request to create a profile
            const url = 'http://bookmyotservice.pythonanywhere.com/CreateHospitalProfile';
    
            const data = {
                inputdata: {
                    hospitalname: userDetails.hospitalName,
                    mobile: userDetails.mobile,
                    email: userDetails.email,
                    username: userDetails.email,
                    psw: userDetails.mobile.slice(-4),
                    tier: null
                }
            };
    
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
    
                if (response.ok) {
                    navigate('http://hospital.bookmyot.com/Account/Login');
                    console.log('Profile created successfully');
                    console.log(response.json())
                } else {
                    console.error('Failed to create profile');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setOtpMessage('Please enter valid OTP');
            console.log('OTP Mismatched. Please enter the correct OTP.');
        }
    };
    


    return(
        <div className="background">
           <div className="d-flex flex-column flex-column-fluid flex-lg-row align-items-center justify-content-center min-vh-100">
                <div className="d-flex flex-center w-lg-100 p-2">
                    <div className="card rounded-3 w-md-500px h-md-460px shadow">
                        <form onSubmit={handleOTPSubmit}>
                            <div className="card-body pt-4">
                                <div className="d-flex flex-wrap gap-5">
                                    <div className="position-relative">
                                        <div className="p-2 text-center">
                                            <h6 className='text-success'>Please Enter The One Time Password <br /> To Verify Your Account</h6>
                                            <div>
                                                <span>A code has been sent to </span>
                                                <small>Email</small>
                                            </div>
                                            <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                                                {otp.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        className="m-2 text-center form-control shadow"
                                                        type="text"
                                                        maxLength="1"
                                                        value={digit}
                                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                                        ref={otpInputRefs[index]}
                                                        required
                                                    />
                                                ))}
                                            </div>
                                            {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                                            {otpMessage && <p style={{ color: 'red' }}>{otpMessage}</p>}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="d-flex justify-content-center mt-5">
                                    <button type="submit" id="btnSaveHospital" className="btn btn-primary">
                                        <span className="indicator-label">
                                            Submit
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form>
                            <input type="submit" value="Resend OTP" style={{ border: "none", backgroundColor: "transparent", color: "skyblue" }} />
                        </form>
                        <br />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OtpVerification;