import './App.css';
// import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import  SignUp  from './components/hospital-signup'
import  OtpVerification  from './components/otp'

function App() {
  return (
    <div>
      <Routes>
        <Route path = '/' element={<SignUp/>}/>
        <Route path="otp" element={<OtpVerification/>} />
      </Routes>
    </div>
  );
}

export default App;
