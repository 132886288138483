import React, { useState } from 'react';
import myImage from '../logo.jpeg';
import { useNavigate } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();
    const [hospitalName, setHospitalName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const data = {
        inputdata: {
            username: email,
            password: mobile.slice(-4),
        },
      };
      
      try {
        const response = await fetch('http://bookmyotservice.pythonanywhere.com/adminorHospitalLogin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
            const responseData = await response.json(); // Wait for the promise to resolve
            console.log(responseData.Status);

            if (responseData.Status === true) {
                console.log('Email already exists, try with another email address.');
            } else {
                navigate('/otp');
                saveUserDetailsToSessionStorage();
                console.log('Profile created successfully');
            }
          } else {
                console.error('Failed to create profile');
          }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const saveUserDetailsToSessionStorage = () => {
        const userDetails = {
          hospitalName,
          email,
          mobile,
          otp: generateOTP(),
          sessionExpiration: Date.now() + 1 * 60 * 1,
        };
    
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    };

    const generateOTP = () => {
        return Math.floor(100000 + Math.random() * 900000).toString();
    };
  
    return (
        <div className="background">
        <img src={myImage} style={{ width: "30%", display: "block", margin: "auto" }} alt="logo" />
          <div className="d-flex flex-column m-auto">
              <div className="d-flex flex-center">
                  <div className="card rounded-3 col-md-5 w-md-700px h-md-460px shadow mt-5" style={{ marginLeft: "460px"}}>
                      <h1 className="text-center mt-4">Hospital Registration</h1>
                      <form onSubmit={handleSubmit} className='mt-3'>
                          <div className="card-body pt-4">
                              <div className="d-flex mb-3">
                                  <div className=" w-100 fv-plugins-icon-container">
                                      <label className="required form-label">Hospital Name</label>
                                      <input type="text"
                                        className="form-control mb-2" 
                                        placeholder="Hospital name" 
                                        value={hospitalName}
                                        onChange={(e) => setHospitalName(e.target.value)}>
                                      </input>
                                      <div className="fv-plugins-message-container invalid-feedback"></div>
                                  </div>
                              </div>
                              <div className="d-flex  gap-5">
                                  <div className=" w-100  fv-plugins-icon-container">
                                      <label className="required form-label">Mobile</label>
                                      <input type="number" 
                                        className="form-control mb-2"
                                        placeholder="Mobile#" 
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)} >
                                      </input>
                                      <div className="fv-plugins-message-container invalid-feedback"></div>
                                  </div>
                                  <div className=" w-100 flex-md-root">
                                      <label className="form-label">Email</label>
                                      <input type="email"
                                        className="form-control mb-2" 
                                        placeholder="Email Id" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}>
                                        </input>
                                      <div className="fv-plugins-message-container invalid-feedback"></div>
                                  </div>
                              </div>
                              <br></br>
                              <p className="text-start fw-bold">Already have an account
                                   <a href="http://hospital.bookmyot.com/Account/Login" className="text-info"> Sign In</a> 
                              </p>
                              <div className="d-flex justify-content-center mt-5">
                                  <button type="submit" className="btn btn-primary">
                                      <span className="indicator-label">
                                          Submit
                                      </span>
                                  </button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    );
  }
  
  export default SignUp;